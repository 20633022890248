@charset "UTF-8";

.clearfix {
    clear: both;
}

.clearfix::after {
    content: "";
    display: table;
}
.wrapper_history{
    width: 90%;
    max-width: 750px;
    margin:  auto;
    border: 10px solid #29abe2;
}
.product{
    margin: 10px 0;
    padding: 15px 20px;
    font-style: italic;
    font-size: 16px;
}
.product > div{
    margin: 5px 0;
}
.product > div svg{
    margin-right: 5px;
    vertical-align: middle;
}
.title_pro{
    text-transform: uppercase;
    font-size: 18px;
    color: #57b55a;
}
.updated_at{
    opacity: 0.7;
}
.updated_at i{
    margin-right: 7px;
}
.wrapper_history .product:nth-child(2n+1){
    background: #ebebeb;
}
.wrapper_history .product:last-child{
    margin-bottom: 0;
}
.wrapper_history .title{
    font-size: 18pt;
    text-align: center;
    font-weight: bold;
    margin: 25px auto;
}
.product .price{
    color: #f50057;
}
