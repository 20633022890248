.card{
  max-height: 50px;
  color: #8ac77a;
}
.popmetamor ::-webkit-scrollbar {
  width: 20px;
}

.popmetamor ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
.popmetamor ::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
}
.popmetamor ::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}
