.input{
    position: relative;
    display: inline-block;
}
.input input{
    display: none;
}
.input i{
    width: 30px;
    height: 30px;
    border: solid 1px #000;
    display: block;
}
.input label{
    cursor: pointer;
}
.input img{
    position: absolute;
    top: 0;
    left: 4px;
    opacity: 0;
}

.input label input:checked ~ img{
    opacity: 1;
}