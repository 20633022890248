.wrapper{
    width: 90%;
    max-width: 965px;
    margin: auto;
    overflow-x: hidden;
    padding: 20px 0;
}
.text_top{
    text-align: left;
    font-size: 42pt;
}
.img_top img{
    max-height: 295px;
    overflow: hidden;
    width: 100%;
}
.category span{
    font-size: 26pt;
}
.category ul{
    list-style: none;
    padidng: 0;
    margin: 0;
}
.category ul li{
    display: inline-block;
    width: 23%;
    margin: 0 1%;
    text-align: center;
    font-size: 28pt;
    color: #fff;
    background:  #000;
    padding: 0 0 4px 0;
    cursor: pointer;
    position: relative;
}
.category ul li:after{
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -10px;
    background: #F7931E;
    width: 100%;
    height: 10px;
}
